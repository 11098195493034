import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { convertSnaps } from '../db-utils';
import { AngularFireStorage } from '@angular/fire/storage/';
import { map, first, take } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { PlaceModalPage } from 'src/app/pages/place-modal/place-modal.page';
import { ModalController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class VendorService {
    vendorRef = this.angularFirestore.collection('features').doc('multiVendor');
    multiVendorRef = this.angularFirestore.collection('features').doc('multiVendor').collection('vendors');
    vendorSettings = {
        isMultiVendorAvailable: false,
        vendorBasedOnKm: false,
        vendorDistance: 0,
        exclusiveVendors: [],
        vendors: [],
        showVendorInfo: false,
        multipleVendorInvoices: false
    }
    userPlaceSub = new BehaviorSubject({
      place: '',
      lat: null,
      lng: null
    });
    userPlace = {
      place: '',
      lat: null,
      lng: null
    };
    constructor(private angularFirestore: AngularFirestore, 
      private angularFireStorage: AngularFireStorage,
      private configService: ConfigService,
      private storage: Storage,
      private modalController: ModalController) { }

    async submitVendorForm(formData, formFile) {
        return new Promise(async (resolve, reject) => {
            let vendorData = formData
            if (formFile) {
                let file = formFile;
                let ext = file.name.split('.').pop();
                console.log(ext)
                let ref = this.angularFireStorage.ref(`vendors/requests/${new Date().getTime().toString()}.${ext}`);
                await ref.put(file);
                let downloadURL = await ref.getDownloadURL().pipe(first()).toPromise();
                console.log(downloadURL)
                vendorData.fileURL = downloadURL;
                await this.vendorRef.collection('requests').add(vendorData).then((docRef) => {
                    console.log("Document written with ID: ", docRef.id);
                    resolve(docRef);
                })
                    .catch((error) => {
                        console.error("Error adding document: ", error);
                    });

            }
            else {
                await this.vendorRef.collection('requests').add(vendorData).then((docRef) => {
                    console.log("Document written with ID: ", docRef.id);
                    resolve(docRef);
                })
                    .catch((error) => {
                        console.error("Error adding document: ", error);
                    });

            }
        });

    }



    async getMultiVendorSettingsDataInSessionStorage() {
        return new Promise(async (resolve, reject) => {
            let multiVendorSettings = sessionStorage.getItem("MultiVendorSettingsData");
            console.log('multiVendorSettings', multiVendorSettings);
            if (multiVendorSettings && multiVendorSettings !== 'undefined') {
                let data = JSON.parse(multiVendorSettings) || {};
                if (data && !(Object.keys(data).length)) {
                    data = await this.vendorRef.valueChanges().pipe(first()).toPromise();
                    sessionStorage.setItem("MultiVendorSettingsData", JSON.stringify(data));
                }
                resolve(data);
            }
            else {
                resolve(null);
            }
        });
    }

    async getVendorData(id: string) {
        return new Promise<any>(async (resolve) => {
            try {
                const vendor = await this.multiVendorRef.doc(id).valueChanges().pipe(first()).toPromise();
                resolve(vendor);
            } catch (error) {
                console.log(error);
            }
        })
    }

    async getActiveStatus(route?) {
        try {
            const multiVendorDoc = await this.angularFirestore.collection('features').doc('multiVendor').valueChanges().pipe(first()).toPromise();
            if (route === 'service') {
                return multiVendorDoc;
            }
        } catch (error) {
            console.dir(error);
        }
    }

    async getMultiVendorSettings() {
        return new Promise(async (resolve) => {
            try {
                const multiVendorDoc: any = await this.angularFirestore.collection('features').doc('multiVendor').valueChanges().pipe(first()).toPromise();
                if (multiVendorDoc && multiVendorDoc.active && this.configService.environment.multiVendor) {
                    this.vendorSettings.isMultiVendorAvailable = true;
                    this.vendorSettings.showVendorInfo = 'showVendorInfo' in multiVendorDoc ? multiVendorDoc.showVendorInfo : false;
                    this.vendorSettings.multipleVendorInvoices = 'multipleVendorInvoices' in multiVendorDoc ? multiVendorDoc.multipleVendorInvoices : false;
                    if (multiVendorDoc.vendorKmBased) {
                        this.vendorSettings.vendorBasedOnKm = true;
                        this.vendorSettings.vendorDistance = multiVendorDoc.vendorKmDistance;
                        this.checkUserLocation();
                    }
                    if (this.vendorSettings.isMultiVendorAvailable) {
                        this.getAllExclusiveVendors();
                    }
                    console.log('this.vendorSettings', this.vendorSettings);
                }
                resolve(multiVendorDoc);
            } catch (error) {
                console.dir(error);
            }
        });
    }

    async getAllExclusiveVendors() {
        return new Promise(async (resolve) => {
            const vendors = await this.angularFirestore.collection('features').doc('multiVendor').collection('vendors', ref => ref
                .where('isExclusive', '==', true))
                .snapshotChanges()
                .pipe(map(snaps => convertSnaps(snaps))).pipe(first()).toPromise();
            if (vendors && vendors.length) {
                this.vendorSettings.exclusiveVendors = vendors.map(v => v.id);
            }
        });
    }

    async checkUserLocation() {
      const userPlace = await this.storage.get('userPlace');
      if(!userPlace) {
        this.openPlaceModal();
      } else {
        this.userPlaceSub.next(userPlace);
        this.userPlace = userPlace;
      }
    }

    async openPlaceModal(action?: string) {
      const modal = await this.modalController.create({
      component: PlaceModalPage,
      backdropDismiss: action === 'showClose' ? true : false
      });
    
      await modal.present();
    
    }

    async saveLocation(userPlace) {
      this.userPlaceSub.next(userPlace);
      this.userPlace = userPlace;
      await this.storage.set('userPlace', userPlace);
    }


}