import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { convertSnaps } from '../db-utils';
import { AngularFireStorage } from '@angular/fire/storage/';
import { map, first } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { PlaceModalPage } from 'src/app/pages/place-modal/place-modal.page';
import { ModalController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@angular/fire/storage";
import * as i3 from "../config/config.service";
import * as i4 from "@ionic/storage";
import * as i5 from "@ionic/angular";
export class VendorService {
    constructor(angularFirestore, angularFireStorage, configService, storage, modalController) {
        this.angularFirestore = angularFirestore;
        this.angularFireStorage = angularFireStorage;
        this.configService = configService;
        this.storage = storage;
        this.modalController = modalController;
        this.vendorRef = this.angularFirestore.collection('features').doc('multiVendor');
        this.multiVendorRef = this.angularFirestore.collection('features').doc('multiVendor').collection('vendors');
        this.vendorSettings = {
            isMultiVendorAvailable: false,
            vendorBasedOnKm: false,
            vendorDistance: 0,
            exclusiveVendors: [],
            vendors: [],
            showVendorInfo: false,
            multipleVendorInvoices: false
        };
        this.userPlaceSub = new BehaviorSubject({
            place: '',
            lat: null,
            lng: null
        });
        this.userPlace = {
            place: '',
            lat: null,
            lng: null
        };
    }
    submitVendorForm(formData, formFile) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let vendorData = formData;
                if (formFile) {
                    let file = formFile;
                    let ext = file.name.split('.').pop();
                    console.log(ext);
                    let ref = this.angularFireStorage.ref(`vendors/requests/${new Date().getTime().toString()}.${ext}`);
                    yield ref.put(file);
                    let downloadURL = yield ref.getDownloadURL().pipe(first()).toPromise();
                    console.log(downloadURL);
                    vendorData.fileURL = downloadURL;
                    yield this.vendorRef.collection('requests').add(vendorData).then((docRef) => {
                        console.log("Document written with ID: ", docRef.id);
                        resolve(docRef);
                    })
                        .catch((error) => {
                        console.error("Error adding document: ", error);
                    });
                }
                else {
                    yield this.vendorRef.collection('requests').add(vendorData).then((docRef) => {
                        console.log("Document written with ID: ", docRef.id);
                        resolve(docRef);
                    })
                        .catch((error) => {
                        console.error("Error adding document: ", error);
                    });
                }
            }));
        });
    }
    getMultiVendorSettingsDataInSessionStorage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let multiVendorSettings = sessionStorage.getItem("MultiVendorSettingsData");
                console.log('multiVendorSettings', multiVendorSettings);
                if (multiVendorSettings && multiVendorSettings !== 'undefined') {
                    let data = JSON.parse(multiVendorSettings) || {};
                    if (data && !(Object.keys(data).length)) {
                        data = yield this.vendorRef.valueChanges().pipe(first()).toPromise();
                        sessionStorage.setItem("MultiVendorSettingsData", JSON.stringify(data));
                    }
                    resolve(data);
                }
                else {
                    resolve(null);
                }
            }));
        });
    }
    getVendorData(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    const vendor = yield this.multiVendorRef.doc(id).valueChanges().pipe(first()).toPromise();
                    resolve(vendor);
                }
                catch (error) {
                    console.log(error);
                }
            }));
        });
    }
    getActiveStatus(route) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const multiVendorDoc = yield this.angularFirestore.collection('features').doc('multiVendor').valueChanges().pipe(first()).toPromise();
                if (route === 'service') {
                    return multiVendorDoc;
                }
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getMultiVendorSettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    const multiVendorDoc = yield this.angularFirestore.collection('features').doc('multiVendor').valueChanges().pipe(first()).toPromise();
                    if (multiVendorDoc && multiVendorDoc.active && this.configService.environment.multiVendor) {
                        this.vendorSettings.isMultiVendorAvailable = true;
                        this.vendorSettings.showVendorInfo = 'showVendorInfo' in multiVendorDoc ? multiVendorDoc.showVendorInfo : false;
                        this.vendorSettings.multipleVendorInvoices = 'multipleVendorInvoices' in multiVendorDoc ? multiVendorDoc.multipleVendorInvoices : false;
                        if (multiVendorDoc.vendorKmBased) {
                            this.vendorSettings.vendorBasedOnKm = true;
                            this.vendorSettings.vendorDistance = multiVendorDoc.vendorKmDistance;
                            this.checkUserLocation();
                        }
                        if (this.vendorSettings.isMultiVendorAvailable) {
                            this.getAllExclusiveVendors();
                        }
                        console.log('this.vendorSettings', this.vendorSettings);
                    }
                    resolve(multiVendorDoc);
                }
                catch (error) {
                    console.dir(error);
                }
            }));
        });
    }
    getAllExclusiveVendors() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const vendors = yield this.angularFirestore.collection('features').doc('multiVendor').collection('vendors', ref => ref
                    .where('isExclusive', '==', true))
                    .snapshotChanges()
                    .pipe(map(snaps => convertSnaps(snaps))).pipe(first()).toPromise();
                if (vendors && vendors.length) {
                    this.vendorSettings.exclusiveVendors = vendors.map(v => v.id);
                }
            }));
        });
    }
    checkUserLocation() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const userPlace = yield this.storage.get('userPlace');
            if (!userPlace) {
                this.openPlaceModal();
            }
            else {
                this.userPlaceSub.next(userPlace);
                this.userPlace = userPlace;
            }
        });
    }
    openPlaceModal(action) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: PlaceModalPage,
                backdropDismiss: action === 'showClose' ? true : false
            });
            yield modal.present();
        });
    }
    saveLocation(userPlace) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.userPlaceSub.next(userPlace);
            this.userPlace = userPlace;
            yield this.storage.set('userPlace', userPlace);
        });
    }
}
VendorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VendorService_Factory() { return new VendorService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.AngularFireStorage), i0.ɵɵinject(i3.ConfigService), i0.ɵɵinject(i4.Storage), i0.ɵɵinject(i5.ModalController)); }, token: VendorService, providedIn: "root" });
